
.random-paper {
    display: flex;
    flex-direction: column;
    height: 96%;
    padding: 30px;
    padding-top: 10px;
    color: #13131b;
    .head {
        margin-bottom: 30px;
        .back-box {
            cursor: pointer;
        }
        .back-box:hover ::v-deep .el-breadcrumb__inner {
            transition: all 0.3s;
            color: #2dc079;
        }
    }
    .main {
        flex: 1;
        overflow: hidden;
        .form-box {
            flex: 1;
            width: 700px;
            span {
                margin: 0 10px;
                white-space: nowrap;
            }
            .set-type-question {
                .hint {
                    color: #999;
                }
                ::v-deep .el-input {
                    width: 180px;
                }
                .type-input-list {
                    width: 555px;
                    transform: translateX(-8.5%);
                    .type-input-item:nth-child(1) {
                        margin-top: 10px;
                    }
                    .type-input-item {
                        display: flex;
                        margin-bottom: 20px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .paper-level {
                width: 100%;
            }
            .sclect-list-box {
                width: 100%;
                ::v-deep .el-cascader {
                    width: 100%;
                }
            }
            .low,
            .high {
                width: 100px;
                ::v-deep input {
                    text-align: center;
                }
            }
            .tags {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    .footer-button {
        display: flex;
        justify-content: center;
        padding-top: 20px;
        ::v-deep .el-button {
            width: 80px;
            height: 40px;
        }
        ::v-deep .blue-button {
            background-color: #1122d8;
            border-color: #1122d8;
        }
    }
}

::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
::v-deep .el-form-item__content {
    display: flex;
}
//去除number input的指示器
/* chrome */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/* 火狐浏览器 */
::v-deep input[type="number"] {
    -moz-appearance: textfield;
}
